<template>
  <div>
    <base-template>
      <template v-slot:content-top>
        <span class="clear-btn" @click="clearMsg" />
        <p class="content-top-remark">{{ $t('userCenter.noReadNum',{num:noReadNum}) }} </p>
      </template>
      <van-list
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public.loading')"
        offset="100"
        @load="onLoad"
      >
        <div v-if="!isEmpty">
          <div class="info-list-box">
            <ul>
              <li v-for="(item,index) in useDataList" :key="index" :class="[!item.srgeervi?'is-no-read':'',iconColor(index)+'-notice-icon']" class="msg-item" @click="goDetail(item)">
                <p class="title">
                  <span>{{ item.fishbeliIla }}</span>
                  <span class="time">{{ item.UkutaifisaNokupa }}</span>
                </p>
                <p class="msg-content">{{ item.mya_fuUmya }}</p>
              </li>
            </ul>
          </div>
        </div>
        <empty-page v-else ref="emptyPage" :image="emptyImage" :description="$t('userCenter.noMsgText')" />
      </van-list>
    </base-template>
  </div>
</template>

<script>
import baseTemplate from '@/components/publicTemplate/components/baseTemplate.vue'
import { getNewsList, newsAllRead } from '@/api/axios/index'
export default {
  components: { baseTemplate },
  data() {
    return {
      loading: false,
      finished: false,
      pageIndex: 0, // 第一页
      pageNum: 10, // 一页10条
      dataLeng: 0,
      isPost: false,
      noticeTop: true,
      colorIndex: 0,
      dataForm: {
        email: 'cbiso9078cs@outlook.com',
        server: '',
        unread_count: ''
      },
      noReadNum: 0,
      isEmpty: false,
      dataList: [],
      useDataList: [],
      emptyImage: require('@/assets/images/message/empty-icon.png')
    }
  },
  created() {
    this.getNewsList()
  },

  methods: {
    onLoad() {
      setTimeout(() => {
        this.pageIndex++
        const list = this.dataList.splice(0, this.pageNum)
        this.useDataList.push(...list)
        this.loading = false
        // 数据全部加载完成
        if (this.useDataList.length >= this.dataLeng) {
          this.finished = true
        }
      }, 1000)
    },
    async getNewsList() {
      if (this.isPost) {
        this.onLoad()
        return
      }
      try {
        const { data } = await getNewsList() || {}
        this.dataList = data?.news_list || []
        this.dataLeng = this.dataList.length
        this.isEmpty = !this.dataList.length
        this.noReadNum = data?.Agicitafyo_nde || 0
        this.isPost = true
        if (this.pageIndex) {
          const list = this.dataList.splice(0, this.pageIndex * this.pageNum)
          this.useDataList = list
        }

        // this.loading = true
      } catch (err) {
        console.log(err)
      }
    },
    // 清除未读消息
    async clearMsg() {
      try {
        await newsAllRead()
        this.isPost = false
        this.getNewsList()
      } catch (error) {
        console.log(error)
      }
    },

    // 查看消息详情
    goDetail(item) {
      this.$router.push({
        path: '/messagesDetail',
        query: {
          news_id: item.news_info_id
        }
      })
      this.$store.dispatch('settings/changeSetting', {
        key: 'newsDetail',
        value: item
      })
    },

    iconColor(index) {
      const color = ['green', 'orange', 'purple', 'red']
      // const colorIndex = Math.floor(Math.random() * (4 - 0) + 0)
      return color[index % 4]
    }
  }
}
</script>
<style lang="scss" scoped>
.info-list-box{
  padding-top: 52px;
  .msg-item{
    height: 80px;
    margin-bottom: 56px;
    background: #FFFFFF;
    position: relative;
    padding-left: 96px;
    &::before{
      content: '';
      position: absolute;
      width: 80px;
      height: 80px;
      left: 0;
      background-size: 100%;
    }
    .title{
      height: 44px;
      line-height: 44px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      span{
        flex: 1;
        font-size: 34px;
        font-weight: 500;
        color: #000000;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      .time{
        font-size: 24px;
        color: #CCCCCC;
        padding-left: 158px;
      }
    }
  .msg-content{
    font-size: 24px;
    color: #999;
    line-height: 36px;
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    /*! autoprefixer: off */
    -webkit-box-orient:vertical;
    -webkit-line-clamp:1;
  }
}
}

:deep(.empty__description){
  margin-top: 0;
  font-size: 32px;
  font-weight: 500;
  color: #1F1F39;
  line-height: 38px;
}

:deep(.el-empty__image){
  width: 332px;
  height: 332px;
  img{
    width: 100%;
  }
}

.is-no-read::after{
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background: #005FE9; 
  bottom:0;
  right: 0;
  border-radius: 50%;
}

.open-btn{
  color: #FF6905FF;
  padding-left: 10px;
}
:deep(.child-title){
  margin-bottom: 8px;
}
.content-top-remark{
  margin-bottom: 32px;
  padding-left: 40px;
  font-size: 28px;
  color: #999;
}
.clear-btn{
  width: 72px;
  height: 72px;
  position: absolute;
  right: 40px;
  top: 120px;
  background: url('~@/assets/images/message/clear-icon.png') no-repeat center center;
  background-size: 100%;
}

.green-notice-icon::before{
  background: url('~@/assets/images/message/green-notice-icon.png') no-repeat center center;
}
.orange-notice-icon::before{
  background: url('~@/assets/images/message/orange-notice-icon.png') no-repeat center center;
}
.purple-notice-icon::before{
  background: url('~@/assets/images/message/purple-notice-icon.png') no-repeat center center;
}
.red-notice-icon::before{
  background: url('~@/assets/images/message/red-notice-icon.png') no-repeat center center;
}

:deep(.empty__description){
  font-size: 26px;
  color: #CCCCCC;
  line-height: 36px;
}

:deep(.el-empty__image img){
  width: 320px;
  height: 300px;
}
</style>
